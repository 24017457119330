import { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationMainSmall.module.scss'
import * as Framework from 'components/framework'
import useKeyPress from 'hooks/useKeyPress'
import useScrollBlock from 'hooks/useScrollBlock'
import { AramarkIconRed } from 'assets/logos'
import Menu from './Menu'
import Search from '../Search'
import Utility from '../Utility'

/**
 * The Navigation - Main - Small Component is used to display the main navigation on pages
 */
const NavigationMainSmall = ({
  analytics,
  disableSticky,
  i18n,
  items,
  link,
  navigationRootPath,
  utilityNav,
  ...props
}) => {
  const [blockScroll, allowScroll] = useScrollBlock()
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const homeText = "home";
  const homePath = navigationRootPath+(i18n?.homeText?`/${i18n?.homeText?.toLowerCase()}`:'/home');

  const toggleMenuOverlay = () => {
    !isMenuActive ? blockScroll() : allowScroll()
    setIsMenuActive(!isMenuActive)
    setIsSearchActive(false)
  }

  const toggleSearchOverlay = () => {
    !isSearchActive ? blockScroll() : allowScroll()
    setIsSearchActive(!isSearchActive)
    setIsMenuActive(false)
  }
  
  useKeyPress('Escape', () => {
    allowScroll()
    setIsSearchActive(false)
    setIsMenuActive(false)
  })

  return (
    <div
      className={`${styles.small} ${disableSticky ? '' : styles.sticky}`}
      data-component='Navigation - Main - Small'
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={`${styles.bar} ${isMenuActive ? styles['menu-active'] : ''} ${isSearchActive ? styles['search-active'] : ''}`}>
            <Framework.Button
              analytics={{
                action: 'Menu',
                component: analytics?.component,
                context: 'Open/Close'
              }}
              addtClassName={`${isMenuActive ? styles.isActive : ''}`}
              content={<><span></span><span></span><span></span><span></span></>}
              label={i18n?.menuText}
              onClick={() => toggleMenuOverlay()}
              theme={{
                style: {},
                type: 'wrapper'
              }}
            />
            <Framework.Anchor
              addtOnClick={() => allowScroll()}
              analytics={{
                component: analytics?.component,
                context: 'Main Nav Home'
              }}
              title={i18n?.homeText}
              href={homePath}
              theme={{
                style: 'primary',
                type: 'wrapper'
              }}
            >
              {AramarkIconRed}
            </Framework.Anchor>
            <Framework.Button
              analytics={{
                action: 'Search',
                component: analytics?.component,
                context: 'Open/Close'
              }}
              content={
                <Framework.Icon icon={isSearchActive ? 'cross' : 'search'} />
              }
              label={i18n?.searchText}
              onClick={() => toggleSearchOverlay()}
              theme={{
                style: {},
                type: 'wrapper'
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.menu} ${isMenuActive ? styles.isActive : ''}`}>
        <div className={styles.inner}>
          <div className={styles.content}>
            <Menu
              addtOnClick={() => allowScroll()}
              analytics={analytics}
              i18n={i18n}
              items={items}
              link={link}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.search} ${isSearchActive ? styles.isActive : ''}`}>
        <div className={styles.inner}>
          <div className={styles.content}>
            <Search
              addtOnSubmit={() => allowScroll()}
              analytics={analytics}
              i18n={i18n}
            />
          </div>
        </div>
      </div>
      {utilityNav && <Utility
        addtOnClick={() => allowScroll()}
        analytics={analytics}
        content={utilityNav}
        isActive={isMenuActive || isSearchActive}
      />}
    </div>
  )
}

NavigationMainSmall.propTypes = {
  /**
   * Analytics payload for tracking interaction events
   */
  analytics: PropTypes.shape({
    component: PropTypes.string,
    context: PropTypes.string
  }),
  /**
   * Disabled Sticky mode
   */
  disableSticky: PropTypes.bool,
  /**
   * i18n - Translation copy
   */
  i18n: PropTypes.object,
  /**
   * Items to render
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      current: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          children: PropTypes.array,
          level: PropTypes.number,
          active: PropTypes.bool,
          current: PropTypes.bool,
          title: PropTypes.string,
          path: PropTypes.string
        })
      ),
      level: PropTypes.number,
      path: PropTypes.string,
      title: PropTypes.string
    })
  ),
  /**
   * Link - Manual Link information
   */
  link: PropTypes.object
}

export default NavigationMainSmall
